/* Header.css */
.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--header-background-color); /* Use CSS variables for theming */
  color: var(--text-primary-color);
  padding: 20px;
}

.Header__inner {
  display: flex;
  align-items: center;
}

.Header__avatar {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-left: 100px;
  margin-right: 20px;
}

.Header__title {
  margin-left: 40px;
  margin-right: 40px;
  font-size: 1.5em;
  font-weight: bold;
}

.BotLinkApp__button {
  margin-left: 20px; /* Push the button to the end of the container */
}

.switch-theme-button {
  background: transparent;
  border: none;
  cursor: pointer;
}

/* Information section */
.Info {
  display: flex;
  align-items: center;
  text-align: right;
}

.Info__item {
  margin-right: 40px;
}

.Info__item h2 {
  margin: 0;
  font-size: 1.2em;
  font-weight: normal;
}

.Info__item b {
  font-weight: bold;
}

/* Theme Variables */
[data-theme='dark'] {
  --header-background-color: #0a0a0a;
  --text-primary-color: #fff;
}

[data-theme='light'] {
  --header-background-color: #eee;
  --text-primary-color: #333;
}


/* Responsive adjustments for mobile */
@media (max-width: 768px) {
  .Header {
    flex-direction: column; /* Stack elements vertically */
    padding: 10px; /* Reduce padding */
  }

  .Header__inner {
    flex-direction: column; /* Stack inner elements vertically */
    align-items: flex-start; /* Align items to the start */
  }

  .Header__avatar {
    margin-bottom: 10px; /* Add some space below the avatar */
    align-items: center;
    margin-left: auto; /* Align to the center of the screen */
    margin-right: auto; /* Align to the center of the screen */
  }

  .Header__title {
    margin-top: 10px;
    margin-left: 0; /* Remove the left margin */
    margin-right: 0; /* Remove the right margin */
    font-size: 1.2em; /* Reduce font size */
    text-align: center; /* Center align the title */
    width: 100%; /* Full width */
  }

  .BotLinkApp__button {
    margin-top: 20px;
    margin-left: 0; /* Remove the left margin */
    width: 100%; /* Full width */
    order: 3; /* Move the button below the title */
  }

  .Info {
    flex-direction: column; /* Stack info items vertically */
    align-items: center; /* Align items to the start */
    margin-top: 20px;
    width: 100%; /* Full width */
    order: 2; /* Move the info section below the avatar */
  }

  .Info__item {
    margin-right: 0; /* Remove the right margin */
    margin-bottom: 10px; /* Add some space below each info item */
  }

  .switch-theme-button {
    margin-top: 20px; /* Add some space above the theme switch button */
    align-self: center; /* Center the button */
  }
}

