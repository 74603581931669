/* App.css */
.search-page {
  text-align: center;
  padding-top: 50px;
}

.logo {
  max-width: 300px;
  margin-bottom: 20px;
}

/* Use flexbox to align the form items */
form {
  display: flex;

  justify-content: center;
  align-items: stretch; /* This will make the button align perfectly with the search bar */
  max-width: 600px; /* Adjust as needed */
  margin: auto; /* This will center the form on the page */
}

.search-bar {
  font-size: 18px;
  padding: 10px;
  flex-grow: 1; /* This will make the search bar take up the available space */
  border: 1px solid #000; /* Add border to match the button */
  border-right: none; /* Remove the right border to blend with the button */
}


.search-button {
  font-size: 18px;
  background-color: transparent;
  align-items: center;
  border: none;
}

.input{
  background-color: lime;
}
/* Responsive adjustments */
@media (max-width: 600px) {
  .search-bar,
  .search-button {
    width: 100%; /* Stacks input and button vertically on small screens */
    margin: 10px 0; /* Adds spacing */
  }
  form {
    flex-direction: column; /* Stacks form items vertically */
  }
}