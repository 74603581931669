/* Container for the buttons */
.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0 10px; /* Adjust the padding as needed */
  width: 100%;
}



/* If you want the text to not wrap */
.button-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
}

/* You might want to add media queries to adjust the font size on smaller screens */
@media (max-width: 600px) {
  .button {
    font-size: 0.8rem;
    padding: 8px 15px;
  }
}



/* Button styling */
.button {

  color: #fff; /* Text color */
  border: none;
  display: flex; /* To align the icon and text */
  align-items: center; /* Vertical alignment */
  gap: 8px; /* Space between icon and text */
  text-decoration: none; /* Remove underline from links */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, color 0.3s; /* Transition for hover effect */
  /* Add width and height if necessary to contain the text and icon */
  width: 100px; /* Adjust width as necessary */
  height: 20px; /* Adjust height as necessary */
  /* Use flex-shrink, flex-grow, and flex-basis for better control in flex containers */
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: auto;
}


.button:hover {
  background-color: #0a0a0a; /* Darken background on hover */
  font-color: #ABF602; /* Lighten text on hover */
  border-color: #ABF602; /* Border color on hover */
}

.button:active {
  background-color: #555; /* Even darker on click */
  border-color: #555; /* Border color on click */
}

/* Icon styling */
.button-icon {
  width: 18px; /* Icon width */
  height: 18px; /* Icon height */
  flex-shrink: 0; /* Prevent the icon from shrinking */
}

/* Text styling */
.button-text {
  display: block; /* Make the text take its own line */
  font-size: 6px; /* Text size */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Ensure the container of the buttons does not allow wrapping if not desired */
.link-app-grid {
  /* ...existing properties... */
  flex-wrap: nowrap; /* Adjust as needed */
}


